import {AppCommonUtils, sendQuery} from "./common/app-common-utils";
import {Role, UserProfile} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {environment} from '../environments/environment';

export class AppContext {
  static userProfile: UserProfile;
  static initials: string;
  static impersonatedUser: string;

  static setUserProfile = (userProfile: UserProfile) => {
    if (!userProfile) {
      AppCommonUtils.clearCache();
    }
    this.userProfile = userProfile;
    this.initials = userProfile && (userProfile.details.firstName + ' ' + userProfile.details.lastName)
      .match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
    sendQuery('getImpersonatedUser').subscribe(v => this.impersonatedUser = userProfile && v);
  }

  static isAdmin = () => Role.admin === this.userProfile.userRole;

  static isOwner = () => this.isAdmin() || Role.owner == this.userProfile.userRole;

  static isProduction = () => environment.production;
}
