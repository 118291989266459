import {AfterViewInit, Component} from '@angular/core';
import {View} from "../../../common/view";
import {Credit, Currency, GetInvoices, Invoice, Organisation} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {ComparatorChain} from "../../../common/comparator-chain";
import {mergeMap, Observable} from "rxjs";
import {HandleQuery} from "../../../common/handle-query";
import {Handler} from "../../../common/handler";
import {map, tap} from "rxjs/operators";
import {sort} from "../../../common/utils";
import {DateFieldRange} from '../../../common/date/date-range/date-field-range';
import {PERIOD_RANGES} from '../../../common/date/date-period-range/date-period-range.component';
import {AppContext} from "../../../app-context";
import {
  CreditDetailsModalComponent,
  CreditDetailsModalComponentData
} from "../credit-details-modal/credit-details-modal.component";
import lodash from "lodash";

@Component({
  selector: 'app-invoice-overview',
  templateUrl: './invoice-overview.component.html',
  styleUrls: ['./invoice-overview.component.scss']
})
@Handler()
export class InvoiceOverviewComponent extends View implements AfterViewInit {
  invoiceComparator: ComparatorChain = new ComparatorChain("-invoiceDate", "-invoiceNumber");
  term: string;
  query: Observable<Invoice[]>;
  data: Invoice[] = [];
  credits: Observable<Credit[]>;
  period: DateFieldRange = {
    start: PERIOD_RANGES.lastTwelveMonths.start.toISOString(),
    end: PERIOD_RANGES.lastTwelveMonths.end.toISOString(),
    label: PERIOD_RANGES.lastTwelveMonths.label
  };

  totalRemaining: number;

  ngAfterViewInit(): void {
    this.executeQuery();
    this.credits = this.subscribeTo("getOrganisation")
      .pipe(map((o: Organisation) => o.credits.filter(c => c.active)))
      .pipe(tap(c => this.totalRemaining = lodash.sum(c.map(c => c.remaining))));
  }

  executeQuery() {
    this.query = this.getInvoices();
  }

  get allowedToEditCredits() {
    return AppContext.isAdmin();
  }

  trackByForRecord(index: number, record: Invoice) {
    return record.id;
  }

  trackByCredit(index: number, record: Credit) {
    return record.creditId;
  }

  @HandleQuery("getInvoices")
  getInvoices(): Observable<Invoice[]> {
    return this.subscribeTo("getOrganisation")
      .pipe(mergeMap((o: Organisation) => this.sendQuery("host.flux.service.invoicing.api.GetInvoices", <GetInvoices>{
        organisationId: o.organisationId,
        timeRange: this.period
      }))).pipe(map((i: Invoice[]) => sort(i, this.invoiceComparator)));
  }

  editCredit = (credit: Credit) => this.openModal(CreditDetailsModalComponent, <CreditDetailsModalComponentData>{
    credit: credit,
    viewContainerRef: this.viewContainerRef
  });

  creditCurrencyIcon = (credit: Credit): string => {
    switch (credit?.details?.currency) {
      case Currency.EUR:
        return "bi-currency-euro";
      case Currency.USD:
        return "bi-currency-dollar";
      default:
        return "bi-currency-euro";
    }
  }
}
