import {Component, inject} from '@angular/core';
import {AppContext} from "../../app-context";
import {AppCommonUtils, sendCommand, sendCommandAndForget} from "../../common/app-common-utils";
import {Handler} from "src/app/common/handler";
import {AuthenticationService} from "../../authentication/authentication.service";
import {View} from '../../common/view';
import {EncryptComponent} from '../admin/encrypt/encrypt.component';
import {OpenModal} from '../../common/modal/modal';

@Component({
  selector: 'app-top-menu-bar',
  templateUrl: './top-menu-bar.component.html',
  styleUrls: ['./top-menu-bar.component.scss']
})
@Handler()
export class TopMenuBarComponent extends View {
  appContext = AppContext;
  authService = inject(AuthenticationService);

  signOut() {
    this.authService.signout();
    AppContext.setUserProfile(undefined);
    AppCommonUtils.navigateToUrl("/login");
  }

  stopImpersonating() {
    sendCommand('stopImpersonating', {}, () => AppCommonUtils.navigateToUrl("", true));
  }

  openEncrypt() {
    sendCommandAndForget("openModal", <OpenModal>{
      component: EncryptComponent
    });
  }
}
