import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {ExtendedAuthorisation} from "../user-overview/user-overview-item/user-overview-item.component";
import {
  GiveAuthorisation,
  Organisation,
  RevokeAuthorisation,
  Role,
  UserProfile
} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {filterByTerm, uuid} from "../../../common/utils";
import {map} from "rxjs/operators";
import {openConfirmationModalWithCallback} from '../../../app-utils';
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from '../../../common/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-authorisation-details',
  templateUrl: './authorisation-details.component.html',
  styleUrls: ['./authorisation-details.component.scss']
})
@Handler()
export class AuthorisationDetailsComponent extends View implements OnInit {
  authorisationRoles: Role[] = [Role.owner, Role.manager, Role.deployer, Role.developer, Role.member];
  isNewAuthorisation: boolean;
  nominatorEditable: boolean;

  nominatorOrganisation: Organisation;
  nominee: UserProfile;

  organisations: Observable<Organisation[]>;

  @Input() data: ExtendedAuthorisation;

  ngOnInit(): void {
    this.organisations = this.subscribeTo("getOrganisations");
    if (!this.data.authorisation.authorisationId) {
      this.isNewAuthorisation = true;
      this.data.authorisation.authorisationId = uuid();
      this.nominatorEditable = !this.data.authorisation.nominator;
    } else {
      this.nominatorEditable = this.data.nominatorEditable;
    }
    this.data.authorisation.entityIds = this.data.authorisation.entityIds || [];
    this.subscribeTo("getUser", this.data.authorisation.nominee)
      .subscribe((o: UserProfile) => this.nominee = o);
    if (this.data.authorisation.nominator) {
      this.subscribeTo("getOrganisation")
        .subscribe((o: Organisation) => this.nominatorOrganisation = o);
    }
  }

  setNominator(organisation: Organisation) {
    this.nominatorOrganisation = organisation;
    this.data.authorisation.nominator = organisation?.organisationId;
  }

  searchOrganisations = (term: string) => this.organisations.pipe(map(o => o.filter(filterByTerm(term))));

  organisationFormatter = (organisation: Organisation) => organisation.details.name;

  updateInfo = () => this.sendCommand("host.flux.service.organisation.api.GiveAuthorisation", <GiveAuthorisation>{
    organisationId: this.data.authorisation.nominator,
    authorisation: this.data.authorisation
  });

  deleteAuthorisation = () => {
    openConfirmationModalWithCallback(confirmed => {
      if (confirmed) {
        this.sendCommand("host.flux.service.organisation.api.RevokeAuthorisation", <RevokeAuthorisation>{
          authorisationId: this.data.authorisation.authorisationId
        });
      }
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData> {
      type: "danger",
      title: "Delete authorisation",
      innerHtmlMessage: `<p>Are you sure you want to delete this authorisation?</p>`,
      confirmText: "Delete authorisation",
      cancelText: "Cancel"
    });
  };
}
