import {Component, OnInit} from '@angular/core';
import {View} from "../../../common/view";
import {Handler} from "../../../common/handler";
import {GetOrganisationHistory, OrganisationHistoryItem} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {debounceTime, mergeMap, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {filterByTerm, sort} from "../../../common/utils";
import {ComparatorChain} from "../../../common/comparator-chain";
import {LocalStorageValue} from "../../../common/utils/local-storage-value";

@Component({
  selector: 'app-history-overview',
  templateUrl: './history-overview.component.html',
  styleUrls: ['./history-overview.component.scss']
})
@Handler()
export class HistoryOverviewComponent extends View implements OnInit {
  private comparatorChain = new ComparatorChain("-item.timestamp", "item.messageId");
  term: string;
  query: Observable<OrganisationHistoryItem[]>;
  data: OrganisationHistoryItem[] = [];
  includeTaskItems: LocalStorageValue<boolean> = new LocalStorageValue("history-include-tasks", false);
  entityIdQuery: Observable<string>;

  ngOnInit(): void {
    this.entityIdQuery = this.subscribeTo("getEntityId");
    this.includeTaskItems.subscribe(() => this.executeQuery(0));
  }

  executeQuery = (debounce: number = 500) => {
    this.query = this.entityIdQuery.pipe(debounceTime(debounce))
      .pipe(mergeMap(entityId =>
        this.sendQuery("host.flux.service.organisation.api.GetOrganisationHistory", <GetOrganisationHistory>{
          entityId: entityId,
          includeTaskItems: this.includeTaskItems.getValue()
        }, {caching: false})
          .pipe(map((o: OrganisationHistoryItem[]) => o.filter(filterByTerm(this.term))))
          .pipe(map(o => sort(o, this.comparatorChain)))
      ));
  };

  trackByForRecord = (index: number, record: OrganisationHistoryItem) => record.item.messageId;

  toggleShowTaskHistory = () => this.includeTaskItems.next(!this.includeTaskItems.getValue());
}
