import {Component, Input} from '@angular/core';
import {DeletePasskey, Passkey} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {AppCommonUtils} from "../../../../../common/app-common-utils";
import {AppContext} from "../../../../../app-context";
import {View} from "../../../../../common/view";
import {Handler} from "../../../../../common/handler";

@Component({
  selector: 'app-passkey-overview-item',
  templateUrl: './passkey-overview-item.component.html',
  styleUrls: ['./passkey-overview-item.component.scss']
})
@Handler()
export class PasskeyOverviewItemComponent extends View {
  @Input() record: Passkey;

  deletePasskey = () => {
    this.sendCommand("host.flux.service.authentication.zitadel.api.DeletePasskey", <DeletePasskey> {
      userId: AppContext.userProfile.userId,
      passkeyId: this.record.id
    }, () => {
      AppCommonUtils.registerSuccess("Passkey successfully deleted");
      this.sendCommandAndForget("reloadOverview");
    });
  }
}
