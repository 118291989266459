import {Component, OnInit} from '@angular/core';
import {AppCommonUtils} from '../../../common/app-common-utils';
import {View} from '../../../common/view';
import {cloneObject, parseJson} from '../../../common/utils';
import moment from 'moment';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent extends View implements OnInit {

  message: AdminRequest;
  historyItems: AdminRequest[];

  ngOnInit(): void {
    this.message = {
      timestamp: moment().toISOString(),
      function: this.message?.function || 'command'
    }
    this.loadHistory();
  }

  loadHistory() {
    this.historyItems = JSON.parse(localStorage.getItem('adminHistory') || '[]');
  }

  restoreHistoryItem(item: AdminRequest) {
    this.message = cloneObject(item);
  }

  updateHistory() {
    this.historyItems.splice(0, 0, cloneObject(this.message));
    this.historyItems = this.historyItems.slice(0, 20);
    localStorage.setItem('adminHistory', JSON.stringify(this.historyItems));
  }

  executeRequest = () => {
    switch (this.message.function) {
      case "command":
        this.sendCommand(this.message.payloadType, parseJson(this.message.payload),
          value => {
            this.updateHistory();
            this.message.queryResult = value || "empty result";
            AppCommonUtils.registerSuccess("Command was executed successfully");
          });
        return;
      case "query":
        super.sendQuery(this.message.payloadType, parseJson(this.message.payload), {caching: false})
          .subscribe({
            next: value => {
              this.updateHistory();
              this.message.queryResult = value || "empty result";
            },
            error: e => e => AppCommonUtils.registerError(e)
          });
        return;
    }
  };
}

export interface AdminRequest {
  timestamp: string;
  function: 'command' | 'query';
  queryResult?: any;
  payloadType?: string;
  payload?: string;
}
