import {Component, Input} from '@angular/core';
import {Handler} from "../../../../common/handler";
import {
  Application,
  Cluster,
  RedeployApplication,
  Task,
  TaskStatus
} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {Observable, of} from "rxjs";
import {map, tap} from "rxjs/operators";
import {sendQuery} from "../../../../common/app-common-utils";
import {HandleQuery} from 'src/app/common/handle-query';
import {View} from "../../../../common/view";
import {TaskWithContext} from "../../../clusters/cluster-routing/cluster-routing.component";

@Component({
  selector: 'app-application-overview-item',
  templateUrl: './application-overview-item.component.html',
  styleUrls: ['./application-overview-item.component.scss']
})
@Handler()
export class ApplicationOverviewItemComponent extends View {
  @Input() application: Application;
  cachedColors: Map<TaskStatus, string> = new Map();

  getColor = (status: TaskStatus): Observable<string> => this.cachedColors.has(status)
    ? of(this.cachedColors.get(status))
    : sendQuery("getTaskStatusColor", status).pipe(tap(c => this.cachedColors.set(status, c)));

  trackByForTask(index: number, record: Task) {
    return record.taskId;
  }

  @HandleQuery("getApplication")
  getApplication(): Observable<Application> {
    return this.subscribeTo("getCluster").pipe(map((c: Cluster) => c.applications
      .find(a => a.applicationId === this.application.applicationId)));
  }

  @HandleQuery("getTasks")
  getTasks(): Observable<TaskWithContext[]> {
    return this.subscribeTo("getApplication").pipe(map((a: Application) => a.tasks.map(t => (<TaskWithContext>{
      task: t,
      application: a
    }))));
  }

  redeploy= () => this.sendCommand("host.flux.service.organisation.api.RedeployApplication", <RedeployApplication>{
    applicationId: this.application.applicationId
  });
}
