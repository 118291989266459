import {Component, Input, OnInit} from '@angular/core';
import {Handler} from "../../../../common/handler";
import {View} from "../../../../common/view";
import {
  Authorisation,
  Organisation,
  RevokeAuthorisation,
  UserProfile
} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AuthorisationDetailsComponent} from "../../authorisation-details/authorisation-details.component";
import {cloneDeep} from "lodash";
import {AppContext} from "../../../../app-context";
import {AppCommonUtils, sendCommand} from "../../../../common/app-common-utils";

@Component({
  selector: 'app-user-overview-item',
  templateUrl: './user-overview-item.component.html',
  styleUrls: ['./user-overview-item.component.scss']
})
@Handler()
export class UserOverviewItemComponent extends View implements OnInit {
  appContext = AppContext;
  @Input() user: UserProfile;
  organisations: Observable<Organisation[]>;
  organisation: Organisation;

  ngOnInit() {
    this.organisations = this.subscribeTo("getOrganisations");
    this.subscribeTo("getOrganisation").subscribe((o: Organisation) => this.organisation = o);
  }

  impersonate = () => sendCommand('impersonateUser', this.user.userId, () => AppCommonUtils.navigateToUrl("", true));

  authorisations = (): Observable<ExtendedAuthorisation[]> => this.organisations
    .pipe(map(orgs => this.user.receivedAuthorisations
      .filter(a => !a.revoked)
      .map(a => ({
        organisationName: orgs.find(o => o.organisationId === a.nominator).details.name,
        entities: a.entityIds.map(id => this.findEntityNameById(id, orgs)),
        authorisation: a
      }))));

  editAuthorisation = (authorisation: ExtendedAuthorisation) => this.openModal(
    AuthorisationDetailsComponent, cloneDeep(authorisation));

  openAuthoriseUserDetails = () => {
    this.openModal(AuthorisationDetailsComponent, <ExtendedAuthorisation>{
      authorisation: {
        nominee: this.user.userId
      },
      entities: [],
      organisationName: null,
      nominatorEditable: AppContext.isAdmin()
    });
  }

  revokeUser = () => this.sendCommand("host.flux.service.organisation.api.RevokeAuthorisation", <RevokeAuthorisation>{
    authorisationId: this.user.receivedAuthorisations.find(a => a.nominator === this.organisation.organisationId).authorisationId
  });

  trackByForAuthorisation = (index: number, record: ExtendedAuthorisation) => record.authorisation.authorisationId;

  trackByForEntity = (index: number, record: EntityInfo) => record.entityId;

  private findEntityNameById = (entityId: string, organisations: Organisation[]): EntityInfo => {
    const organisation = organisations.find(o => o.organisationId === entityId);
    if (organisation) {
      return {
        entityId: entityId,
        name: organisation.details.name,
        type: "Organisation"
      };
    }
    const team = organisations.flatMap(o => o.teams)
      .find(t => t.teamId === entityId);
    if (team) {
      return {
        entityId: entityId,
        name: team.details.name,
        type: "Team"
      };
    }
    const cluster = organisations.flatMap(o => o.teams
      .flatMap(t => t.clusters)).find(t => t.clusterId === entityId);
    if (cluster) {
      return {
        entityId: entityId,
        name: cluster.details.name,
        type: "Cluster"
      };
    }
    return null;
  }

  getEntityTypeIcon = (type: EntityTypes): string => {
    switch (type) {
      case "Organisation": return "bi-diagram-3";
      case "Team": return "bi-people";
      case "Cluster": return "bi-window-stack";
    }
  }
}

export interface ExtendedAuthorisation {
  organisationName: string;
  entities: EntityInfo[];
  authorisation: Authorisation;
  nominatorEditable?: boolean;
}

type EntityTypes = "Organisation" | "Team" | "Cluster";

interface EntityInfo {
  entityId: string;
  name: string;
  type: EntityTypes;
}
