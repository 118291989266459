import {Component, OnInit} from '@angular/core';
import {View} from "../../../common/view";
import {CreateOrganisation, Organisation, UpdateOrganisation} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {Handler} from 'src/app/common/handler';
import {uuid} from "../../../common/utils";
import {AppContext} from "../../../app-context";

@Component({
  selector: 'app-organisation-details-modal',
  templateUrl: './organisation-details-modal.component.html',
  styleUrls: ['./organisation-details-modal.component.scss']
})
@Handler()
export class OrganisationDetailsModalComponent extends View implements OnInit {
  data: OrganisationDetailsComponentData;

  isNewRecord: boolean;

  ngOnInit() {
    this.isNewRecord = !this.data.organisation?.organisationId;
    this.data.organisation = this.data.organisation || this.defaultRecord();
  }

  updateInfo = () => {
    if (this.isNewRecord) {
      this.sendCommand("host.flux.service.organisation.api.CreateOrganisation", <CreateOrganisation> {
        organisationId: this.data.organisation.organisationId,
        details: this.data.organisation.details,
        owner: AppContext.userProfile.userId,
        createDefaultTeam: true
      });
    } else {
      this.sendCommand("host.flux.service.organisation.api.UpdateOrganisation", <UpdateOrganisation>{
        organisationId: this.data.organisation.organisationId,
        details: this.data.organisation.details
      });
    }
  };

  defaultRecord = (): Organisation => ({
    organisationId: uuid(),
    details: {
      address: {}
    }
  })
}

export interface OrganisationDetailsComponentData {
  organisation: Organisation;
}
