import {Component, inject} from '@angular/core';
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {
  Application,
  Cluster,
  GetApiKeys,
  GetUsage,
  GetUsers,
  InstantRange,
  Organisation,
  TaskUsageEntry,
  Team,
  UserProfile
} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {map} from "rxjs/operators";
import {HandleQuery} from "../../../common/handle-query";
import {mergeMap, Observable, of} from "rxjs";
import {GetApplications, GetClusters, GetTeams} from "../../../app.component";
import {ActivatedRoute} from "@angular/router";
import {TaskWithContext} from "../../clusters/cluster-routing/cluster-routing.component";
import lodash from "lodash";
import {sendQuery} from "../../../common/app-common-utils";

@Component({
  selector: 'app-organisation-routing',
  templateUrl: './organisation-routing.component.html',
  styleUrls: ['./organisation-routing.component.scss']
})
@Handler()
export class OrganisationRoutingComponent extends View {
  private route = inject(ActivatedRoute);
  organisationId: string;

  constructor() {
    super();
    this.route.params.subscribe(params => this.organisationId = params["organisationId"]);
  }

  @HandleQuery("getEntityId")
  getEntityId(): Observable<string> {
    return this.subscribeTo("getOrganisation").pipe(map((o: Organisation) => o.organisationId));
  }

  @HandleQuery("getEntity")
  getEntity(): Observable<any> {
    return this.subscribeTo("getOrganisation");
  }

  @HandleQuery("getOrganisation")
  getOrganisation(): Observable<Organisation> {
    return this.subscribeTo("getOrganisations").pipe(map((organisations: Organisation[]) =>
      organisations.find(o => o.organisationId === this.organisationId)));
  }

  @HandleQuery("getTeams")
  getTeams(query?: GetTeams): Observable<Team[]> {
    return this.subscribeTo("getOrganisation", query).pipe(map((o: Organisation) => o.teams));
  }

  @HandleQuery("getTeam")
  getTeam(query?: GetTeams): Observable<Team> {
    return this.subscribeTo("getTeams", query).pipe(map(teams => teams[0]));
  }

  @HandleQuery("getCluster")
  getCluster(clusterId: string) {
    return this.subscribeTo("getClusters").pipe(map(c => c.find(c => c.clusterId === clusterId)));
  }

  @HandleQuery("getApplications")
  getApplications(query?: GetApplications): Observable<Application[]> {
    return this.subscribeTo("getClusters", <GetClusters>{teamId: query?.teamId, organisationId: query?.organisationId})
      .pipe(map((clusters: Cluster[]) => clusters.filter(t => !query?.clusterId || t.clusterId === query?.clusterId)
        .flatMap(t => t.applications)));
  }

  @HandleQuery("getApplication")
  getApplication(applicationId?: string): Observable<Application> {
    return applicationId ? this.subscribeTo("getApplications", <GetApplications>{organisationId: this.organisationId})
        .pipe(map((as: Application[]) => as.find(a => a.applicationId === applicationId)))
      : of(null);
  }

  @HandleQuery("getTasks")
  getTasks(): Observable<TaskWithContext[]> {
    return this.subscribeTo("getClusters", <GetClusters>{organisationId: this.organisationId})
      .pipe(map((clusters: Cluster[]) => clusters.flatMap(c => c.applications.flatMap(
        a => a.tasks.map(t => (<TaskWithContext>{
          cluster: c,
          application: a,
          task: t
        }))))));
  }

  @HandleQuery("getApiKeys")
  getApiKeys(query?: GetApiKeys): Observable<UserProfile[]> {
    return this.subscribeTo("getOrganisations").pipe(mergeMap(() => this.sendQuery(
      "host.flux.service.user.api.GetApiKeys", this.applyOrganisationFilter(query), {caching: false})));
  }

  @HandleQuery("getApiKey")
  getApiKey(userId: string): Observable<UserProfile> {
    return lodash.isEmpty(userId) ? of(null) : this.getApiKeys().pipe(map(
      users => users.find(u => u.userId === userId)));
  }

  @HandleQuery("getUsers")
  getUsers(query: GetUsers = {}): Observable<UserProfile[]> {
    return this.subscribeTo("getOrganisations").pipe(mergeMap(() => this.sendQuery(
      "host.flux.service.user.api.GetUsers", this.applyOrganisationFilter(query), {caching: false})));
  }

  @HandleQuery("getUser")
  getUser(userId: string): Observable<UserProfile> {
    return lodash.isEmpty(userId) ? of(null) : this.getUsers().pipe(map(
      users => users.find(u => u.userId === userId)));
  }

  @HandleQuery("getUserOrApiKey")
  getUserOrApiKey(userId: string): Observable<UserProfile> {
    return lodash.isEmpty(userId) ? of(null) : sendQuery("host.flux.service.user.api.GetUsers",
      undefined, {caching: false})
      .pipe(map((u: UserProfile[]) => u.find(u => u.userId === userId)))
      .pipe(mergeMap(u => u ? of(u) : sendQuery("host.flux.service.user.api.GetApiKeys",
        undefined, {caching: false}).pipe(map(
          (u: UserProfile[]) => u.find(u => u.userId === userId)))));
  }

  @HandleQuery("getUsage")
  getUsage(timeRange: InstantRange): Observable<TaskUsageEntry[]> {
    return this.sendQuery("host.flux.service.usage.api.GetUsage", <GetUsage>{
      timeRange: timeRange,
      facetFilters: [{
        facetName: "organisationId",
        values: [this.organisationId]
      }]
    });
  }

  private applyOrganisationFilter(query: GetApiKeys | GetUsers) {
    query.facetFilters = query.facetFilters || [];
    let facetFilter = query.facetFilters.find(f => f.facetName === "receivedAuthorisations/nominator")
    if (!facetFilter) {
      query.facetFilters.push({facetName: "receivedAuthorisations/nominator", values: [this.organisationId]});
    } else if (!facetFilter.values?.includes(this.organisationId)) {
      facetFilter.values = [this.organisationId];
    }
    return query;
  }
}
