import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {InstantRange} from "@flux-capacitor-io/flux-host-typescriptmodels";
import moment from "moment";

@Pipe({
  name: 'formatRange'
})
@Injectable()
export class FormatRangePipe implements PipeTransform {

    transform(value: InstantRange, format: string = undefined, ...args: any[]) {
        return FormatRangePipe.format(value, format);
    }

    static format(value: InstantRange, format: string = "DD MMM YYYY"): string {
      if (!value) {
        return null;
      }
      const start = value.start ? moment(value.start).format(format) : "no start time";
      const end = value.end ? moment(value.end).format(format) : "no end time";
      return `${start} – ${end}`;
    }
}
