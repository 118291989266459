import {Component, inject, ViewContainerRef} from '@angular/core';
import {AppCommonUtils, subscribeTo} from "./common/app-common-utils";
import {Handler} from "src/app/common/handler";
import {HandleQuery} from "./common/handle-query";
import {
  Cluster,
  ClusterSize,
  Currency,
  Organisation,
  TaskSize,
  Team,
  UiUpdate,
  UserProfile
} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {Observable, of} from "rxjs";
import {HandleEvent} from './common/handle-event';
import * as jsonPatch from "fast-json-patch";
import {ActivatedRoute} from "@angular/router";
import {map} from "rxjs/operators";
import {HandleCommand} from './common/handle-command';
import {ModalComponent} from './common/modal/modal.component';
import {defaultModalOptions, OpenModal} from './common/modal/modal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
@Handler()
export class AppComponent {
  appUtils = AppCommonUtils;
  route = inject(ActivatedRoute);
  viewContainerRef: ViewContainerRef = inject(ViewContainerRef);

  @HandleCommand("openModal")
  openModal(command: OpenModal) {
    const container = command.container || this.viewContainerRef;
    const modal = container.createComponent(ModalComponent);
    container.element.nativeElement.appendChild(modal.location.nativeElement);
    command.options = command.options || defaultModalOptions;
    const optionsCallback = command.options.closeCallback;
    command.options.closeCallback = (args?) => {
      try {
        if (optionsCallback) {
          optionsCallback(args);
        }
      } finally {
        modal.destroy();
      }
    }
    setTimeout(() => modal.instance.openModal(command), 0);
  }

  @HandleQuery("getOrganisations")
  getOrganisations(): Observable<Organisation[]> {
    return subscribeTo("host.flux.service.organisation.api.GetOrganisations");
  }

  @HandleQuery("getTeams")
  getTeams(query?: GetTeams): Observable<Team[]> {
    return subscribeTo("getOrganisations")
      .pipe(map((organisations: Organisation[]) => organisations
        .filter(o => !query?.organisationId || o.organisationId === query?.organisationId)
        .flatMap(o => o.teams)));
  }

  @HandleQuery("getClusters")
  getClusters(query?: GetClusters): Observable<Cluster[]> {
    return subscribeTo("getTeams", <GetTeams>{organisationId: query?.organisationId})
      .pipe(map((teams: Team[]) => teams.filter(t => !query?.teamId || t.teamId === query?.teamId)
        .flatMap(t => t.clusters.filter(c => c.status !== 'destroyed'))));
  }


  @HandleQuery("getClusterSizes")
  getClusterSizes(): Observable<ClusterSize[]> {
    return of([ClusterSize.tiny, ClusterSize.xsmall, ClusterSize.small, ClusterSize.medium, ClusterSize.large, ClusterSize.xlarge]);
  }

  @HandleQuery("getTaskSizes")
  getTaskSizes(): Observable<TaskSize[]> {
    return of([TaskSize.tiny, TaskSize.xsmall, TaskSize.small, TaskSize.medium, TaskSize.large, TaskSize.xlarge, TaskSize.huge]);
  }

  @HandleQuery("getCurrencies")
  getCurrencies(): Observable<Currency[]> {
    return of([Currency.EUR]);
  }

  @HandleEvent("Organisation")
  handleOrganisationUpdate(event: UiUpdate) {
    AppCommonUtils.modifyQueryCache("host.flux.service.organisation.api.GetOrganisations", (organisations: Organisation[]) => {
      const before = organisations.find(o => o.organisationId === event.id);
      const patchResult = jsonPatch.applyPatch(before, event.patch);
      const after = patchResult.newDocument;
      if (!before) {
        organisations.push(after);
      }
      return [...organisations];
    });
  }

  @HandleEvent("UserProfile")
  handleUserUpdate(event: UiUpdate) {
    AppCommonUtils.modifyQueryCache("host.flux.service.user.api.GetUserProfile", (user: UserProfile) =>
      event.id === user.userId ? jsonPatch.applyPatch(user, event.patch).newDocument : user);
  }
}

export interface GetTeams {
  organisationId?: string;
}

export interface GetClusters extends GetTeams {
  teamId?: string;
}

export interface GetApplications extends GetClusters {
  clusterId?: string;
}

export interface GetTasks extends GetApplications {
  applicationId?: string;
}
