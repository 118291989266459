export const environment = {
  production: true,
  apiProtocol: "https://",
  apiDomain: "api.dashboard.flux.host",
  auth: {
    domain: "https://auth.flux.host",
    clientId: "296225125349523520",
    redirectBaseUri: "https://dashboard.flux.host"
  }
};
