import {Component, Input, OnInit} from '@angular/core';
import {
  Application,
  Cluster,
  OrganisationHistoryItem,
  Task,
  UserProfile
} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {Observable} from "rxjs";
import {Handler} from "../../../../common/handler";
import {View} from "../../../../common/view";

@Component({
  selector: 'app-history-overview-item',
  templateUrl: './history-overview-item.component.html',
  styleUrls: ['./history-overview-item.component.scss']
})
@Handler()
export class HistoryOverviewItemComponent extends View implements OnInit {
  @Input() historyItem: OrganisationHistoryItem;
  application: Observable<Application>;
  cluster: Observable<Cluster>;
  task: Observable<Task>;
  user: Observable<UserProfile>;

  ngOnInit(): void {
    this.application = this.subscribeTo("getApplication", this.historyItem.applicationId);
    this.cluster = this.subscribeTo("getCluster", this.historyItem.clusterId);
    this.user = this.subscribeTo("getUserOrApiKey", this.historyItem.item.userId);
  }
}
