import {AfterViewInit, Component, Input} from '@angular/core';
import {Handler} from "src/app/common/handler";
import {Cluster, Team} from "@flux-capacitor-io/flux-host-typescriptmodels";
import {Observable} from "rxjs";
import {ComparatorChain} from "../../../common/comparator-chain";
import {map} from "rxjs/operators";
import {filterByTerm, sort} from "../../../common/utils";
import {GetTeams} from "../../../app.component";
import {
  ClusterDetailsModalComponent,
  ClusterDetailsModalComponentData
} from "../cluster-details-modal/cluster-details-modal.component";
import {HandleQuery} from "../../../common/handle-query";
import clusterStatusInfos from "../cluster-status-info.json";
import {View} from "../../../common/view";

@Component({
  selector: 'app-cluster-overview',
  templateUrl: './cluster-overview.component.html',
  styleUrls: ['./cluster-overview.component.scss']
})
@Handler()
export class ClusterOverviewComponent extends View implements AfterViewInit {
  @Input() team: Team;
  clustersComparator: ComparatorChain = new ComparatorChain("details.name", "clusterId");

  term: string;
  query: Observable<Cluster[]>;
  data: Cluster[] = [];

  ngAfterViewInit(): void {
    this.executeQuery();
  }

  createCluster = () => this.openModal(ClusterDetailsModalComponent, <ClusterDetailsModalComponentData>{
    cluster: null,
    team: this.team
  });

  executeQuery() {
    this.query = this.getClusters();
  }

  trackByForRecord(index: number, record: Cluster) {
    return record.clusterId;
  }

  @HandleQuery("getClusters")
  getClusters() {
    return this.subscribeTo("getTeam", <GetTeams>{teamId: this.team.teamId})
      .pipe(map((t: Team) => t.clusters.filter(c => c.status !== 'destroyed')))
      .pipe(map((c) => c.filter(filterByTerm(this.term))))
      .pipe(map(c => sort(c, this.clustersComparator)));
  }

  @HandleQuery("getCluster")
  getCluster(clusterId: string) {
    return this.subscribeTo("getClusters").pipe(map(c => c.find(c => c.clusterId === clusterId)));
  }

  @HandleQuery("getClusterStatusInfo", {caching: true})
  getClusterStatusInfo(cluster: Cluster): Observable<ClusterStatusInfo> {
    return this.subscribeTo("getCluster", cluster.clusterId).pipe(map(
      (c: Cluster) => clusterStatusInfos[c.status] as ClusterStatusInfo))
  }
}

export interface ClusterStatusInfo {
  icon: string;
  iconColor: string;
}
