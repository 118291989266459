import {Component, ElementRef, forwardRef, Input, ViewChild} from '@angular/core';
import moment, {unitOfTime} from "moment";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {AbstractValueAccessorComponent} from "../../component/value-accessor.component";
import {DateFieldRange, MomentDateFieldRange} from "../../date/date-range/date-field-range";
import {DateRangeComponent} from "../../date/date-range/date-range.component";
import {dispatchChangeEvent} from "../../utils";
import {TranslateDirective} from "../../utils/translate.directive";

@Component({
  selector: 'app-facet-date-range',
  templateUrl: './facet-date-range.component.html',
  styleUrls: ['./facet-date-range.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FacetDateRangeComponent), multi: true}
  ]
})
export class FacetDateRangeComponent extends AbstractValueAccessorComponent<DateFieldRange> {
  @Input() filterName: string;
  @Input() ranges: MomentDateFieldRange[] = [];

  @ViewChild("dateRangeComponent") dateRangeComponent: DateRangeComponent;

  protected _dateRange: DateFieldRange;

  constructor(private elementRef: ElementRef) {
    super();
  }

  openDatePicker = () => {
    setTimeout(() => this.dateRangeComponent.openCalendar(), 100);
  }

  get value(): DateFieldRange {
    return this._dateRange;
  }

  writeValue(value: DateFieldRange): void {
    this._dateRange = value;
  }

  dateRangeChange = (t: DateFieldRange) => {
    this.writeValue(t);
    dispatchChangeEvent(this.elementRef.nativeElement);
    this.onModelChange();
  }

  selectRange(range: MomentDateFieldRange) {
    this.dateRangeChange({
      label: range.label,
      start: range.start.toISOString(),
      end: range.end.toISOString()
    });
  }

  removeFilter = () => {
    this.dateRangeChange(null);
  }

  get dateLabel(): string {
    if (!this._dateRange) {
      return "Select a date or range";
    }
    const timeRange: MomentDateFieldRange = {
      start: moment(this._dateRange.start),
      end: moment(this._dateRange.end),
      label: this._dateRange.label
    };
    if (timeRange.label) {
      return TranslateDirective.getTranslation(timeRange.label, true);
    }
    const isSame = {
      day: timeRange.start.isSame(timeRange.end, 'day'),
      month: timeRange.start.isSame(timeRange.end, 'month'),
      wholeMonth: timeRange.start.isSame(timeRange.end, 'month') && this.isSame(timeRange, 'month'),
      year: timeRange.start.isSame(timeRange.end, 'year'),
      wholeYear: timeRange.start.isSame(timeRange.end, 'year') && this.isSame(timeRange, 'year')
    };
    if (isSame.day && isSame.month && isSame.year) {
      return timeRange.start.format("DD MMM YYYY");
    }
    if (isSame.month && isSame.wholeMonth && isSame.year) {
      return timeRange.start.format("MMMM YYYY");
    }
    if (isSame.wholeYear) {
      return timeRange.start.format("YYYY");
    }
    return `${timeRange.start.format("DD MMM YYYY")} - ${timeRange.end.format("DD MMM YYYY")}`;
  }

  private isSame(timeRange: MomentDateFieldRange, unitOfTime: unitOfTime.StartOf) {
    if (unitOfTime === 'year' && !this.isSame(timeRange, 'month')) {
      return false;
    }
    return timeRange.start.isSame(timeRange.start.clone().startOf(unitOfTime), 'day')
      && timeRange.end.isSame(timeRange.end.clone().endOf(unitOfTime), 'day');
  }

  get hasValue() {
    return !!this._dateRange;
  }
}
